import GTag from './gtagMethods'
import AM from './amplitudeMethods'
import YA from './yandexMethods'

import { getParams } from 'utils/getURLData'
import { StatusVocabulary, TypeCodes } from '../../constants/data'
import {
  A_WEBINAR_FUTURE_OPEN,
  A_WEBINAR_LIVE_OPEN,
  A_WEBINAR_PAST_OPEN,
  A_ARTICLE_OPEN,
  A_ARTICLE_VIDEO_OPEN,
  A_CLINIC_OPEN,
  A_UNSUBSCRIBE_WEBINAR,
  A_SUBSCRIBE_WEBINAR,
  A_UNSUBSCRIBE_LECTOR,
  A_SUBSCRIBE_LECTOR,
  A_NONAME_WEBINAR_OPEN,
  A_COURSE_CLICK_PROGRAM,
  YES,
  NO,
} from '../../constants/analytic-data'
import { IStringObj } from '../../constants/types/common.types'
import { SessionStorageKeys } from 'constants/storage'

const AmplitudeWebinarName: IStringObj = {
  [StatusVocabulary.FUTURE]: A_WEBINAR_FUTURE_OPEN,
  [StatusVocabulary.ACTIVE]: A_WEBINAR_LIVE_OPEN,
  [StatusVocabulary.PAST]: A_WEBINAR_PAST_OPEN,
}

const AmplitudeArticleName: IStringObj = {
  [TypeCodes.ARTICLE]: A_ARTICLE_OPEN,
  [TypeCodes.VIDEO]: A_ARTICLE_VIDEO_OPEN,
  [TypeCodes.CLINIC]: A_CLINIC_OPEN,
}

const setEvent = (event: string, data?: IStringObj) => {
  GTag.event(event, data)
  AM.setEvent(event, data)
  YA.setEvent(event, data)
}

const setOriginEvent = (event: string, data?: IStringObj) => {
  let formatedData = { ...data }

  if (data && 'origin' in data && !data.origin) {
    const sessionStorageOrigin =
      window.sessionStorage.getItem(SessionStorageKeys.ANALYTIC_ORIGIN) || ''
    window.sessionStorage.removeItem(SessionStorageKeys.ANALYTIC_ORIGIN)
    formatedData = { ...formatedData, origin: sessionStorageOrigin }
  }

  setEvent(event, formatedData)
}

const setWebinarSubscription = (id: number, currentStatus: boolean, subSource: string) => {
  const event = currentStatus ? A_UNSUBSCRIBE_WEBINAR : A_SUBSCRIBE_WEBINAR
  const data = {
    id: String(id),
    subSource,
  }
  setEvent(event, data)
}

const setLectorSubscription = (id: number, method = '', currentStatus: boolean) => {
  const event = currentStatus ? A_UNSUBSCRIBE_LECTOR : A_SUBSCRIBE_LECTOR
  const data = {
    id: String(id),
    method,
  }
  setEvent(event, data)
}

const setWebinarOpen = (
  webinarID: number,
  webinarStatus: number,
  nmo?: number,
  isFilledUser?: boolean
) => {
  const event = AmplitudeWebinarName[webinarStatus]
  const params = getParams(window.location.href)
  const data = {
    id: String(webinarID),
    origin: params.origin || '',
  }
  setOriginEvent(event, data)

  const activeStatuses = [StatusVocabulary.ACTIVE, StatusVocabulary.HOUR_BEFORE]
  if (activeStatuses.includes(webinarStatus) && !isFilledUser) {
    setEvent(A_NONAME_WEBINAR_OPEN, { ...data, nmo: nmo ? YES : NO })
  }
}

const setArticleOpen = (articleID: number, articleType: number, origin?: string) => {
  const event = AmplitudeArticleName[articleType]
  const params = getParams(window.location.href)
  const data = {
    id: String(articleID),
    origin: origin || params.origin || '',
  }
  setOriginEvent(event, data)
}

const setCourseOpen = (courseID: number, origin?: string) => {
  const params = getParams(window.location.href)
  const data = {
    id: String(courseID),
    origin: params.origin || origin || '',
  }

  setOriginEvent(A_COURSE_CLICK_PROGRAM, data)
}

const A = {
  setEvent,
  setOriginEvent,
  setWebinarSubscription,
  setLectorSubscription,
  setArticleOpen,
  setWebinarOpen,
  setCourseOpen,
}
export default A
