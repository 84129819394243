const buttonIdAcceptCookie = 'acceptCookieButton'
const buttonIdProfessionalAttention = 'professionalAttentionButton'
const buttonIdNavbarLogin = 'navBarLoginButton'
const buttonIdLoginFormEnter = 'loginFormEnterButton'
const buttonIdAnotherCountry = 'anotherCountryButton'
const buttonIdArgeeCheckup = 'argeeCheckupButton'
const buttonIdWebinarEnded = 'webinarEnded'
const buttonIdWebinarStarted = 'webinarStarted'
const buttonIdWebPushNotNow = 'webPushNotNow'
const buttonIdSoundTurnOn = 'soundTurnOn'
const buttonIdQuestionnaire = 'questionnaire'
const buttonIdNotification = 'notification'
const buttonIdFillProfile = 'fillProfile'

const fieldIdLoginFormEmail = 'loginFormEmailField'
const fieldIdLoginFormPassword = 'loginFormPasswordField'

export {
  buttonIdAcceptCookie,
  buttonIdProfessionalAttention,
  buttonIdNavbarLogin,
  buttonIdLoginFormEnter,
  buttonIdAnotherCountry,
  buttonIdArgeeCheckup,
  buttonIdWebinarEnded,
  buttonIdWebinarStarted,
  buttonIdWebPushNotNow,
  buttonIdSoundTurnOn,
  buttonIdQuestionnaire,
  buttonIdNotification,
  buttonIdFillProfile,
  fieldIdLoginFormEmail,
  fieldIdLoginFormPassword,
}
