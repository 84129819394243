import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import InputPassword from 'components/form-parts/form-password-react/form-password-react'

import { fieldData, FieldsNameVoc } from 'constants/auth/profile-data'

interface IProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  dynamicName?: boolean
  noErrorOnTouch?: any
  onBlur?: any
  noName?: boolean
  name: string
  nameStar?: boolean
  rules?: {
    required: string
    validate: (val: string) => string | undefined
  }
  id?: string // Этот айди нужен скрипта скринов
}

const ControllerPasswordInput: FC<IProps> = ({
  control,
  errors,
  name = FieldsNameVoc.password,
  rules,
  noName,
  dynamicName,
  noErrorOnTouch,
  ...props
}: IProps) => {
  return (
    <Controller
      control={control}
      name={name ? name : FieldsNameVoc.password}
      rules={rules}
      render={({ field: { ref, ...rest } }) => {
        return (
          <InputPassword
            error={errors[name]}
            inputRef={ref}
            noName={noName}
            onClick={() => noErrorOnTouch?.(name)}
            dynamicName={dynamicName}
            {...rest}
            {...fieldData[name]}
            {...props}
          ></InputPassword>
        )
      }}
    />
  )
}

export default ControllerPasswordInput
