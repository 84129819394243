import {
  A_RECOVERY_TRANSITION,
  A_REGISTRATION_TRANSITION,
  A_AUTHORIZATION_TRANSITION,
  A_RECOVERY_SUCCESS_TRANSITION,
} from 'constants/analytic-data'
import { popupName } from 'constants/auth/auth-data'

const AdditionPageTitle = {
  [popupName.login]: 'Авторизация',
  [popupName.registration]: 'Регистрация',
  [popupName.recoverPassword]: 'Восстановление пароля',
  [popupName.recoverSuccess]: 'Восстановление пароля успешно',
}

const TransitionEvent = {
  [popupName.login]: A_AUTHORIZATION_TRANSITION,
  [popupName.registration]: A_REGISTRATION_TRANSITION,
  [popupName.recoverPassword]: A_RECOVERY_TRANSITION,
  [popupName.recoverSuccess]: A_RECOVERY_SUCCESS_TRANSITION,
}

const LoginHashPopup: Array<string> = [popupName.login, popupName.loginStudents]
const RegHashPopup: Array<string> = [
  popupName.registration,
  popupName.nopassRegistration,
  popupName.registrationStudents,
  popupName.registrationWithSteps,
  popupName.geriatricsSchool,
]

export { AdditionPageTitle, TransitionEvent, LoginHashPopup, RegHashPopup }
