import { useState } from 'react'
import { useRouter } from 'next/router'

import Auth from 'store/auth'
import useActivePopupStatus from 'store/common/useActivePopupStatus'
import useMedcoinData from 'store/common/useMedcoinData'

import A from 'utils/metrics/analytic'
import { cutHashFromURL, getParams, concatParams } from 'utils/getURLData'
import { postAxiosSingle, getRequest, setToken } from 'pages/api/axios'
import { GTAGLoginGA } from 'utils/metrics/analyticMethods'
import { AUTH_URL, PROMO_REDIRECT_URL, MAIN_PAGE_URL } from 'constants/data'
import { profileURL } from 'constants/url'
import { A_AUTHORIZATION_SUCCESS_TRANSITION } from 'constants/analytic-data'
import { loginInitValues } from 'constants/auth/profile-data'

const getPath = (path: string) => {
  const params = getParams(path)
  const formedParams = concatParams(params)
  const completePath = formedParams ? `${MAIN_PAGE_URL}?${formedParams}` : MAIN_PAGE_URL
  return completePath
}

const useLogin = () => {
  const router = useRouter()
  const { setHashPopupStatus } = useActivePopupStatus()
  const { setAuth } = Auth.useHandleAuth()

  const { updateCoinsAmount } = useMedcoinData()

  const [isError, setError] = useState(false)
  const [inUse, setInUse] = useState(false)

  const onSubmit = async (values: typeof loginInitValues) => {
    const path = cutHashFromURL(router.asPath)
    setInUse(true)
    try {
      const data = await postAxiosSingle(`${AUTH_URL}/login`, {}, values)

      if (data.status === 200) {
        const { accessToken, expireTime, currentTime } = data.data
        setToken(accessToken)
        const currentProfile = await getRequest(profileURL)

        setAuth({
          userProfile: currentProfile.data,
          accessToken,
          expireTime,
          currentTime,
        })
        GTAGLoginGA()
        updateCoinsAmount()
        A.setEvent(A_AUTHORIZATION_SUCCESS_TRANSITION)

        const url = path === PROMO_REDIRECT_URL ? getPath(path) : path
        router.replace(url, undefined, { shallow: true })
        setHashPopupStatus()
      } else {
        setError(true)
      }
    } catch (err) {
      console.log(err)
      setError(true)
    }
    setInUse(false)
  }

  return {
    isError,
    inUse,
    setError,
    onSubmit,
  }
}

export default useLogin
