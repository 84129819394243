import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useForm, useFormState } from 'react-hook-form'
import Link from 'next/link'

import Button from 'components/parts/button/button'
import ControllerBaseInput from 'components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import ControllerPasswordInput from 'components/form-parts/controller-hook-forms/controller-password-input/controller-password-input'
import useActivePopupStatus from 'store/common/useActivePopupStatus'
import useLogin from './useLogin'

import A from 'utils/metrics/analytic'
import { A_AUTH_LOGIN_ERRORS } from 'constants/analytic-data'
import { cutHashFromURL } from 'utils/getURLData'
import { popupName } from 'constants/auth/auth-data'
import {
  buttonIdLoginFormEnter,
  fieldIdLoginFormEmail,
  fieldIdLoginFormPassword,
} from 'constants/clickableElementsId'
import { FieldsNameVoc, emailValidation, passwordValidation } from 'constants/auth/profile-data'

import wrapperStyles from '../auth-popup-wrapper/auth-popup-wrapper.module.scss'
import styles from './login-form.module.scss'

const errorText = 'Неверный логин или пароль'

interface ILoginForm {
  onSubmitEnd?: () => void
  regIsHidden?: boolean
  noAlterAuth?: boolean
  handleClose?: () => void
}

const inputStyles = {
  modificator: wrapperStyles.input,
  inputNameModificator: styles.nameRowStyle,
}

const LoginForm: FC<ILoginForm> = ({ onSubmitEnd, regIsHidden }) => {
  const router = useRouter()
  const { isError, inUse, setError, onSubmit } = useLogin()
  const { hashPopupStatus } = useActivePopupStatus()
  const path = cutHashFromURL(router.asPath)

  const onSubmitForm = async (data: any) => {
    try {
      await onSubmit(data)
      onSubmitEnd?.()
    } catch {
      console.log('send error')
    }
  }

  const { handleSubmit, control, clearErrors } = useForm({
    mode: 'onBlur',
  })

  const { errors } = useFormState({ control })

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const isButtonDisabled = Boolean(inUse || Object.keys(errors).length)

  const isStudentsLogin = hashPopupStatus === popupName.loginStudents
  const registrationHash = isStudentsLogin
    ? popupName.registratiomStudentsOlymp
    : popupName.registration

  useEffect(() => {
    if (isError) {
      A.setEvent(A_AUTH_LOGIN_ERRORS, { type: 'wrong_pass' })
    }
  }, [isError])

  return (
    <div className={styles.wrapper}>
      <div className={styles.topTitle}>Вход в аккаунт</div>
      <form
        id="form"
        onSubmit={handleSubmit(onSubmitForm)}
        onKeyDown={handleKeyPress}
        autoComplete="on"
        className={styles.form}
      >
        <div className={styles.outerContainer}>
          <div
            className={styles.innerInput}
            onClick={() => {
              clearErrors(FieldsNameVoc.email)
              setError(false)
            }}
          >
            <ControllerBaseInput
              control={control}
              name={FieldsNameVoc.email}
              errors={errors}
              rules={emailValidation}
              nameStar
              id={fieldIdLoginFormEmail}
              {...inputStyles}
            />
          </div>
          <div
            onClick={() => {
              clearErrors(FieldsNameVoc.password)
              setError(false)
            }}
          >
            <ControllerPasswordInput
              control={control}
              name={FieldsNameVoc.password}
              errors={errors}
              rules={passwordValidation}
              nameStar
              id={fieldIdLoginFormPassword}
              {...inputStyles}
            />
          </div>
        </div>
        {isError && <p className={styles.errorResponse}>{errorText}</p>}
        <div className={styles.createAcc}>
          {!regIsHidden && (
            <div className={styles.registerContainer}>
              <p className={styles.title}>Нет аккаунта?&nbsp;</p>
              <Link
                className={styles.jumpLogin}
                href={`${path}#${registrationHash}`}
                prefetch={false}
              >
                Зарегистрироваться
              </Link>
            </div>
          )}
        </div>
        <div className={styles.logInContainer}>
          <div className={styles.button}>
            <Button
              type="submit"
              modificator={styles.buttonMaxWidth}
              disabled={isButtonDisabled}
              id={buttonIdLoginFormEnter}
            >
              Войти в аккаунт
            </Button>
          </div>
          {!regIsHidden && (
            <Link
              className={styles.logInRemember}
              href={`${path}#${popupName.recoverPassword}`}
              prefetch={false}
            >
              <p className={styles.logInRememberText}>Забыли пароль?</p>
            </Link>
          )}
        </div>
      </form>
    </div>
  )
}

export default LoginForm
